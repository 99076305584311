import React from "react"

const Analytics = () => {
  return (
    <div>
        {/* Global site tag (gtag.js) - Google Analytics */}
        <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-QEWG0V4FWN"
        />
        <script>{injectGA()}</script>
    </div>
  )
}

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'G-QEWG0V4FWN');
};

export default Analytics
