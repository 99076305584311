// Skills Icons
import nodeJsIcon from "./images/node-js.svg"
import angularIcon from "./images/angular.svg"
import cloudIcon from "./images/cloud.svg"
import pythonIcon from "./images/python.svg"
import cogsIcon from "./images/cogs.svg"
import csharpIcon from "./images/c-sharp.png"

// Social Icon
import githubIcon from "./images/github.svg"
import linkedInIcon from "./images/linkedin.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Maxime Lardier",
  headerTagline: [
    //Line 1 For Header
    "Computer Science",
    //Line 2 For Header
    "student at Epitech",
    //Line 3 For Header
    "Paris",
  ],
  //   Header Paragraph
  headerParagraph:
    "Computer Science student in 5th year, passionate about Web Development and DevOps. I love new technologies and content creation.",

  //Contact Email
  contactEmail: "max@💻.kz",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Pathfinder visualizer",
      para:
        "Web application to visualize pathfinding algorithms and create mazes.",
      imageSrc:
        "https://images.unsplash.com/photo-1590278458425-6aa3912a48a5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2098&q=80",
      url: "https://pathfinding-visualizer.maximelardier.dev/",
    },
    {
      title: "Audio Shop", //Project Title - Add Your Project Title Here
      para:
        "Serverless web application to buy audio tracks.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1590602846989-e99596d2a6ee?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        // "https://images.unsplash.com/photo-1504083898675-c896ecdae86e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjJ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://audioshop.netlify.app/",
    },
    {
      title: "Chantier Techno Services", //Project Title - Add Your Project Title Here
      para:
        "Web application to validate workers presence onsite and manage tools.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://max-portfolio-imgs.s3.eu-west-3.amazonaws.com/workers.png",
        // "https://images.unsplash.com/photo-1607720146778-68d2d56fa38c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2187&q=80",
        // "https://images.unsplash.com/photo-1487837647815-bbc1f30cd0d2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Njl8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://chantier.techno-services.fr/",
    },
    {
      title: "Under Size Me", //Project Title - Add Your Project Title Here
      para:
        "Simple url shortener application.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://max-portfolio-imgs.s3.eu-west-3.amazonaws.com/directions.png",
        // "https://images.unsplash.com/photo-1483213097419-365e22f0f258?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        // "https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=3450&q=80",
        // "https://images.unsplash.com//photo-1605153864431-a2795a1b2f95?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwyNTY3ODl8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://url.maximelardier.dev",
    },
    {
      title: "Algoflex (WIP)", //Project Title - Add Your Project Title Here
      para:
        "Platform to train and improve algorithmic skills with specific constraints.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1542831371-29b0f74f9713?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        // "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "",
    },
    // {
    //   title: "Instagram Clone", //Project Title - Add Your Project Title Here
    //   para:
    //     "Instagram inspired mobile application to take, save and share pictures.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1611262588024-d12430b98920?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2167&q=80",
    //     // "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "#",
    // },
    {
      title: "Machine learning (WIP)", //Project Title - Add Your Project Title Here
      para:
        "Model analysing x-ray images of patients to detect pneumonia.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here

      imageSrc:
        "https://images.unsplash.com/photo-1501526029524-a8ea952b15be?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        // "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "#",
    },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "I am software engineer student living in Paris. I like to create interactive and UX focused websites.",
  aboutParaTwo:
    "Currently working part-time as an apprentice for Contentsquare as a Software Engineer.",
  aboutParaThree:
    "Before this, I worked for Transactis a joint venture between Société Générale and La Banque Postale, and Capcod a web agency producing websites for mutliple local companies.",
  aboutImage:
    "https://images.unsplash.com/photo-1503917988258-f87a78e3c995?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzl8fHBhcmlzfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60",
    // "https://images.unsplash.com/photo-1580415726270-7152783c7d06?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80",
    // "https://images.unsplash.com/photo-1587919562949-24e7725f79fd?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OHx8c3RyYXNib3VyZ3xlbnwwfDF8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60",
    // "https://images.unsplash.com/photo-1601589312959-bd0c1b752b3e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8c3RyYXNib3VyZ3xlbnwwfDF8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80",
    // "https://images.unsplash.com/photo-1519713958759-6254243c4a53?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80",
  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: nodeJsIcon,
      para:
        "Proficient with NodeJS",
    },
    {
      img: angularIcon,
      para:
        "Proficient with Angular",
    },
    {
      img: pythonIcon,
      para:
        "Familiar with python",
    },
    {
      img: csharpIcon,
      para:
        "Familiar with C# and .net core",
    },
    {
      img: cogsIcon,
      para:
        "Passionate about DevOps and automation",
    },
    {
      img: cloudIcon,
      para:
        "Familiar with cloud, passed Azure AZ-900 formation and deployed projects on AWS",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Projects",
  promotionPara:
    "Thanks for visiting my portfolio, I'm currently not ",
    // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Reach out",
  social: [
    // Add Or Remove The Link Accordingly
    { 
      img: githubIcon,
      url: "https://github.com/Pakome/"
    },
    {
      img: linkedInIcon,
      url: "https://www.linkedin.com/in/maximelardier/",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
